<style>
    .nav-self {
        align-items: center;
    }

        .nav-self a p, .nav_logout {
            font-size: 20px;
            font-weight: 500;
            color: #9abdc2;
        }

    .nav_link {
        border-right: 1px solid #9abdc2;
        height: 1.25rem;
    }

    .nav_registration, .nav_login, .nav_logout {
        padding: 0 1rem;
    }
</style>
<template>
    <div>
        <!--fa-header 版头-->
        <section class="fa-header" canvas style="z-index: 1000">
            <section class="header-bar">
                <!--<h1 class="branding"><a href="index">o血拼</a></h1>-->
                <h1 class="" style="font-size:xx-large;"><a href="index">o血拼</a></h1>
                <section class="nav-self">
                    <!--账号登入时的显示-->
                    <p v-show="isLogin" class="nav_logout">
                        Hello, <span>{{username}}</span>
                        <div v-show="isLogin" class="nav_link"></div>
                        <a v-show="isLogin" class="nav_logout" @click="Logout()">登出</a>
                    </p>
                    <!--/账号登入时的显示-->
                    <!--账号登出,或需要注册时的显示-->
                    <a v-show="!isLogin" href="registration" class="nav_registration"><p>注册</p></a>
                    <div v-show="!isLogin" class="nav_link"></div>
                    <a v-show="!isLogin" href="login" class="nav_login"><p>登入</p></a>
                    <!--/账号登出,或需要注册时的显示-->

                    <a id="btn-service" href="#lightbox-service" class="btn-self"><i class="icon-service"></i><em>service</em></a>
                    <a href="inquire" class="btn-self"><i class="icon-list"></i><em>order list</em></a>
                    <a href="cart" class="btn-self">
                        <i class="icon-buy"></i><em>cart</em><span class="cart-count spancc" id="spancc">0</span>
                    </a>
                    <a id="btn-menu" class="btn-self lg-hide md-hide sm-show xs-show"><i class="icon-menu"></i><em>nav</em></a>
                </section>
            </section>
            <nav class="nav-main">
                <ul>
                    <li v-for="(item, key) in categoryData" :key="key">
                        <a :href="'Category?prodCateId=' + item.Id">{{ item.Name }}</a>
                    </li>
                </ul>
            </nav>
        </section>
        <div canvas="container" class="fa-wrap">
            <!--fa-content 内容-->
            <section class="fa-content">
                <!--<router-link to="/home">Home</router-link> |
                    <router-link to="/about">About</router-link> |
                    <router-link to="/aliyun">AliyunTest</router-link> |
                    <router-link to="/login">Login</router-link> |
                    <router-link to="/createagent">CreateAgent</router-link> |
                    <router-link to="/settinglist">SettingList</router-link> |
                    <router-link to="/agentlist">AgentList</router-link> |
                    <router-link to="/tinymceeditor">TinymceEditor</router-link> |
                    <router-link to="/noticeboardlist">NoticeBoardList</router-link> |
                    <router-link to="/noticeboardeditor">NoticeBoardEditor</router-link>-->
                <router-view :key="$route.fullPath"></router-view>
            </section>
            <!--fa-content 内容-->
            <!--fa-footer 版尾-->
            <footer class="fa-footer">
                <section>
                    <address class="copyright">
                        Copyright &copy; {{ new Date().getFullYear() }}
                        <a href="index">o血拼</a> All rights reserved.
                    </address>
                </section>
                <section>
                    <p class="nav-sub">
                        <!--<a href="https://webecportal.azurewebsites.net/p/index" target="_blank">CGP商城</a>-->
                        <a href="#">使用者条款</a>
                        <a href="#">隐私权政策</a>
                    </p>
                </section>
            </footer>
            <!--/fa-footer 版尾-->
        </div>
        <!--主頁內容-->
        <!--fa-mobile 手机版选单-->
        <aside class="fa-mobile" off-canvas="fa-mobile right push">
            <ul>
                <li v-for="(item, key) in categoryData" :key="key">
                    <a :href="'Category?prodCateId=' + item.Id">{{ item.Name }}</a>
                </li>
            </ul>
        </aside>
        <!--/fa-mobile 手机版选单-->

        <div style="display: none">
            <article id="lightbox-service" class="lightbox lightbox-service">
                <h3>客服服务时间</h3>
                <p>在線专员服务時間：周一至周五 10:00-18:00</p>
                <p>遇周末/國定假日洽询，请邮件至：service@oshopping.com</p>
                <p class="btnarea">
                    <a href="https://cgpmall.chatbox.one/member/login" class="btn-active">联络客服</a>
                </p>
            </article>
        </div>
    </div>
</template>
<style>
    body {
        overflow: auto !important;
    }
</style>
<script>
    function GetUserName() {
        let username = localStorage.getItem("username");
        if (username?.length > 0) {
            //console.log(username);
            return username;
        }
    }
    function SetUserName(username) {
        localStorage.setItem("username", username);
    }
    function GetLoginStatus() {
        let isLogin = localStorage.getItem("isLogin");
        return isLogin;
    }
    function SetLoginStatus(loginStatus) {
        localStorage.setItem("isLogin", loginStatus);
    }
    import { GetAllCategoryWithChild, GetActiveBanners } from "@/service/api";
    import { siteName } from "@/service/config";

    export default {
        name: "Layout",
        data() {
            return {
                isLogin: false,
                username: "",
                categoryData: [],
                currentCategory: "",
                currentKeyword: "",
                carouselData: [],
                //cartitems: [],
            };
        },
        mounted: function () {
            let loginStatus = GetLoginStatus();
            if (loginStatus === 'true') {
                this.isLogin = true;
                this.username = GetUserName();
            }
            GetAllCategoryWithChild()
                .then((resp) => {
                    this.categoryData = resp.data;
                })
                .catch((data) => {
                    if (data.status === 401) alert("Error: token is not found;");
                    if (data.status === 403) alert("Error: authentication failed;");
                });
            GetActiveBanners()
                .then((resp) => {
                    this.carouselData = resp.data;
                })
                .catch((data) => {
                    if (data.status === 401) alert("Error: token is not found;");
                    if (data.status === 403) alert("Error: authentication failed;");
                });
            this.RefreshCart();
            //lightbox
            $("#btn-service").colorbox({ inline: true, width: "300px" });
            // 手機版選單
            // Initialize Slidebars
            var controller = new slidebars();
            controller.init();

            // Toggle Slidebars
            $("#btn-menu").on("click", function (event) {
                // Stop default action and bubbling
                event.stopPropagation();
                event.preventDefault();

                // Toggle the Slidebar with id 'id-1'
                controller.toggle("fa-mobile");
            });

            //導覽列定位
            $(".fa-wrap").scroll(function () {
                var winW = $(window).width();
                var scroll = $(".fa-wrap").scrollTop();
                if (winW > 768) {
                    if (scroll > 0) {
                        $(".fa-header .header-bar").addClass("_top");
                    } else {
                        $(".fa-header .header-bar").removeClass("_top");
                    }
                } else {
                    $(".fa-header .header-bar").removeClass("_top");
                }
            });
            $(".nav-main a").hover(
                function () {
                    $(this).parent().children(".lv2").show();
                },
                function () {
                    $(this).parent().children(".lv2").hide();
                }
            );
            $(".nav-main .lv2").hover(
                function () {
                    $(this).show();
                },
                function () {
                    $(this).hide();
                }
            );

            // search 搜尋列開關
            $(".btn-search").click(function () {
                $(".search-form").toggle();
            });

            // //banner 輪播
            // $(".slide-slick").slick({
            //   autoplay: true,
            //   dots: true,
            // });

            //marquee
            // $(".marquee").marquee({
            //   speed: 5000,
            //   gap: 50,
            //   delayBeforeStart: 0,
            //   direction: "left",
            //   duplicated: true,
            //   pauseOnHover: true,
            // });

            //resize
            $(window).resize(function () {
                $(".slide-slick").slick("resize");

                //filter check
                var winW = $(window).width();
                if (winW > 768) {
                    $(".menu-category").show();
                } else {
                    $(".menu-category").hide();
                }
            });
        },
        methods: {
            Logout() {
                console.log("logout");
                SetUserName("");
                SetLoginStatus(false);
                this.isLogin = false;
            },
            GetImageUrl: function (imgId, size) {
                var d = new Date();
                var n = d.getTime();
                return (
                    "https://n2imageservice.azurewebsites.net/api/images/shopmall/" + imgId + "/" + size + "/0?" + n
                );
            },
            SetActive: function (obj) {
                $("#navbarNav.active").removeClass("active");
                $(this).addClass("active");
            },
            RefreshCart: function () {
                let cartitems = Object.keys(localStorage).filter((o) =>
                    o.startsWith("cart_")
                );
                if (cartitems.length > 0) {
                    $("#spancc").html(cartitems.length);
                    $(".spancc").html(cartitems.length);
                    $(".spancc").show();
                } else {
                    $(".spancc").hide();
                }
            },
            Search: function () {
                this.$router
                    .push({
                        path: "Search",
                        query: { querystring: this.currentKeyword },
                    })
                    .catch((err) => {
                        err;
                    });
            },
            SetWindowTitle: function (name) {
                window.document.title = name + " - " + siteName;
            },
        },
        updated: function () {
            setTimeout(function () {
                $(".slide-slick").not(".slick-initialized").slick({
                    autoplay: true,
                    dots: true,
                });
            }, 1000);
        },
    };
    $(function () { });
</script>

<style>
</style>